/**
 * Easy selector helper function
 */
const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}

/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
  if (all) {
    select(el, all).forEach(e => e.addEventListener(type, listener))
  } else {
    select(el, all).addEventListener(type, listener)
  }
}

(function ($) {
  "use strict";
  /* Navbar Scripts */
  // jQuery to collapse the navbar on scroll
  $(window).on('scroll load', function () {
    if ($(".navbar").offset().top > 60) {
      $(".fixed-top").addClass("top-nav-collapse");
    } else {
      $(".fixed-top").removeClass("top-nav-collapse");
    }
  });

  // jQuery for page scrolling feature - requires jQuery Easing plugin
  $(function () {
    $(document).on('click', 'a.page-scroll', function (event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top
      }, 600, 'easeInOutExpo');
      event.preventDefault();
    });
  });

  // offcanvas script from Bootstrap + added element to close menu on click in small viewport
  $('[data-toggle="offcanvas"], .navbar-nav li a:not(.dropdown-toggle').on('click', function () {
    $('.offcanvas-collapse').toggleClass('open')
  })

  // hover in desktop mode
  function toggleDropdown(e) {
    const _d = $(e.target).closest('.dropdown'),
      _m = $('.dropdown-menu', _d);
    setTimeout(
      function () {
      const shouldOpen = e.type !== 'click' && _d.is(':hover');
      _m.toggleClass('show', shouldOpen);
      _d.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave'
      ? 300
      : 0);
  }
  $('body').on('mouseenter mouseleave', '.dropdown', toggleDropdown).on('click', '.dropdown-menu a', toggleDropdown);
  /* Image Slider 1 - Swiper */
  var imageSliderOne = new Swiper('.image-slider-1', {
    autoplay: {
      delay: 2000,
      disableOnInteraction: false
    },
    loop: true,
    spaceBetween: 30,
    slidesPerView: 5,
    breakpoints: { // when window is <= 575px
      575: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      // when window is <= 767px
      767: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window is <= 991px
      991: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      // when window is <= 1199px
      1199: {
        slidesPerView: 4,
        spaceBetween: 20
      }
    }
  });
  /* Image Slider 2 - Swiper */
  var imageSliderTwo = new Swiper('.image-slider-2', {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
  /* Card Slider - Swiper */
  var cardSlider = new Swiper('.card-slider', {
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 3,
    spaceBetween: 70,
    breakpoints: { // when window is <= 767px
      767: {
        slidesPerView: 1
      },
      // when window is <= 991px
      991: {
        slidesPerView: 2,
        spaceBetween: 40
      }
    }
  });
  /* Image Lightbox - Magnific Popup */
  $('.popup-link').magnificPopup({
    removalDelay: 300,
    type: 'image',
    callbacks: {
      beforeOpen: function () {
        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure ' + this.st.el.attr('data-effect'));
      },
      beforeClose: function () {
        $('.mfp-figure').addClass('fadeOut');
      }
    },
    gallery: {
      enabled: true // enable gallery mode
    }
  });
  /* Details Lightbox - Magnific Popup */
  $('.popup-with-move-anim').magnificPopup({
    type: 'inline',
    fixedContentPos: true,
    fixedBgPos: true,
    overflowY: 'auto',
    closeBtnInside: true,
    preloader: false,
    midClick: true,
    removalDelay: 300,
    mainClass: 'my-mfp-slide-bottom'
  });
  /* Video Lightbox - Magnific Popup */
  $('.popup-youtube, .popup-vimeo').magnificPopup({
    disableOn: 0,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
      patterns: {
        youtube: {
          index: 'youtube.com/',
          id: function (url) {
            var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
            if (! m || ! m[1])
              return null;

            return m[1];
          },
          src: 'https://www.youtube.com/embed/%id%?autoplay=1'
        },
        vimeo: {
          index: 'vimeo.com/',
          id: function (url) {
            var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
            if (! m || ! m[5])
              return null;

            return m[5];
          },
          src: 'https://player.vimeo.com/video/%id%?autoplay=1'
        }
      }
    }
  });
  /* Counter - CountTo */
  var a = 0;
  $(window).scroll(function () { // checking if CountTo section exists in the page, if not it will not run the script and avoid errors
    if ($('#counter').length) {
      var oTop = $('#counter').offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        $('.counter-value').each(function () {
          var $this = $(this),
            countTo = $this.attr('data-count');
          $({countNum: $this.text()}).animate({
            countNum: countTo
          }, {
            duration: 2000,
            easing: 'swing',
            step: function () {
              $this.text(Math.floor(this.countNum));
            },
            complete: function () {
              $this.text(this.countNum);
              // alert('finished');
            }
          });
        });
        a = 1;
      }
    }
  });
  /* Move Form Fields Label When User Types */
  // for input and textarea fields
  $("input, textarea").on('keyup', function () {
    if ($(this).val() != '') {
      $(this).addClass('notEmpty');
    } else {
      $(this).removeClass('notEmpty');
    }
  });
  /* Back To Top Button */
  // create the back to top button
  $('body').prepend(`<a href="body" class="back-to-top page-scroll">
    <span class="sr-only">Back to Top</span>
  </a>`);
  var amountScrolled = 700;
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > amountScrolled) {
      $('a.back-to-top').fadeIn('500');
    } else {
      $('a.back-to-top').fadeOut('500');
    }
  });
  /* Removes Long Focus On Buttons */
  $(".button, a, button").on('mouseup', function () {
    $(this).trigger('blur');
  });
})(jQuery);
/**
 * Portfolio isotope and filter
 */
window.addEventListener('load', () => {
  let portfolioContainer = select('.portfolio-container');
  if (portfolioContainer) {
    let portfolioIsotope = new Isotope(portfolioContainer, {
      itemSelector: '.portfolio-item',
      layoutMode: 'fitRows'
    });
    let portfolioFilters = select('#portfolio-filters li', true);
    on('click', '#portfolio-filters li', function (e) {
      e.preventDefault();
      portfolioFilters.forEach(function (el) {
        el.classList.remove('filter-active');
      });
      this.classList.add('filter-active');
      portfolioIsotope.arrange({filter: this.getAttribute('data-filter')});
      aos_init();
    }, true);
  }
});
